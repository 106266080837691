import React from "react";
import "./BottomInfoStyle.scss";

const BottomInfo = () => {
  return (
    <div className="BottomInfo">
      <div className="BottomInfo container-fluid">
        <div className="row justify-content-md-center">
          <div className="BottomInfo__text col-lg-12">
            <div className="Bottom_logo">
              <img src="/static/asset/logo_onlytypo.svg" alt="logo" />
            </div>

            <ul>
              <li>상호: 주식회사 팜에어</li>
              <li>대표자 : 권민수</li>
              <li>사업자등록번호 723-87-00593</li>
              <li>통신판매신고번호 : 제 2021-강원원주-01077 호</li>
              <li>본사: 강원도 춘천시 동산면 종자리로 33-7</li>
              <li>
                연구소: 서울특별시 성동구 연무장15길 11 에스팩토리 B동, 2층
              </li>

              {/* <li className="bottom_info_3">Tel: 02 6952 5225</li> */}
              <li>Fax: 070-8228-6856</li>
              <li>
                Copyright(c) <span>FarmAir</span> All rights reserved
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomInfo;
