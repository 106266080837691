import React from "react";
import "./AboutStyle.scss";
import Cards from "./Cards";
import { cards } from "../../info";

const About = () => {
  return (
    <div className="About" id="ABOUT">
      <div className="About container-fluid h-100">
        <h1>ABOUT US</h1>
        <div className="row align-items-center justify-content-center">
          <div className="about_card_container">
            {cards.map((item, i) => (
              <div key={i} className="about_card_box">
                <Cards
                  title={item.title}
                  src={item.src}
                  description={item.description}
                  index={i}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
