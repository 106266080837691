import React, { Component } from "react";
import "./HeaderStyle.scss";

class Header extends Component {
  scroll = (id: string) => {
    const getId = document.getElementById(id);
    if (getId !== null) {
      getId.scrollIntoView({ behavior: "smooth" });
    }
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    return (
      <div className="Header">
        <div className="container-fluid">
          <div className="row">
            <button
              className="Header__logo col-12 col-md-3 col-lg-4"
              onClick={this.scrollToTop}
            >
              <img alt="logo with typo" src="/static/asset/logo_onlytypo.svg" />
            </button>
            <div className="Header__menu col-12 col-md-7 offset-md-2 offset-lg-2 col-lg-6">
              <ul>
                <li
                  onClick={() => {
                    this.scroll("ABOUT");
                  }}
                >
                  <button>ABOUT</button>
                </li>
                <li
                  onClick={() => {
                    this.scroll("SERVICE");
                  }}
                >
                  <button>SERVICE</button>
                </li>
                <li
                  onClick={() => {
                    this.scroll("CLIENT");
                  }}
                >
                  <button>CLIENT</button>
                </li>
                <li
                  onClick={() => {
                    this.scroll("CONTACT");
                  }}
                >
                  <button>CONTACT</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
