export interface Card {
  title: string;
  src: string;
  description: string[];
}

const cards: Card[] = [
  {
    title: "미션",
    src: "/static/asset/about/img_about1.png",
    description: [
      "정보기술을 기반으로 농업을 재해석",
      "농업의 지속 가능한 발전을 추구",
    ],
  },
  {
    title: "핵심 가치",
    src: "/static/asset/about/img_about2.png",
    description: ["품위 있는 이윤을 추구", "사회적으로 존경받을 수 있는 선택"],
  },
  {
    title: "인재",
    src: "/static/asset/about/img_about3.png",
    description: [
      "공감하는 인재",
      "열정적인 인재",
      "상호 존중하는 인재",
      "유머감각이 있는 인재",
    ],
  },
  {
    title: "비전",
    src: "/static/asset/about/img_about4.png",
    description: ["애그테크 선두주자", "혁신적인 농업 유통 구조 완성"],
  },
];

export interface Service {
  imageLogo: string;
  title: string;
  imageSource: string;
  description: string[];
  serviceName?: string;
}

export interface ServiceDetail {
  title: string;
  description: string;
  imageSource: string;
}

const servicesDetails: ServiceDetail[][] = [
  // farm
  [
    {
      title: "품종별 실시간 유통 데이터 및 인사이트 제공",
      description: `총 22개 품목의 유통 정보 및 주산지 정보 제공\n시각화 자료를 통한 다각화된 인사이트 제공`,
      imageSource: "/static/asset/serviceDetail/img_detail_farm_1.png",
    },
    {
      title: "농산물 시세 기술적 분석 가능",
      description: `주식시장에서 사용되는 투자분석 기법으로 기술적 분석이 가능\n농산물 시장에 맞는 분석 지표를 제공하여 작물별 시세 흐름을 연구할 수 있음`,
      imageSource: "/static/asset/serviceDetail/img_detail_farm_3.png",
    },
    {
      title: "농산물 가격의 다양한 미래 예측 시나리오 제공",
      description: `목적에 맞게 학습된 알고리즘에 따라 단기/장기 예측 시나리오 제공`,
      imageSource: "/static/asset/serviceDetail/img_detail_farm_4.png",
    },
  ],

  // meat
  [
    {
      title: "주요 KLPI 지수 및 실시간 거래 데이터 제공",
      description:
        "국내 도매시장에서 거래되는 주요 축산물 거래 데이터를 기반으로 국내 축산물 시장의 흐름을 확인할 수 있는 축산물 지수 개발",
      imageSource: "/static/asset/serviceDetail/img_detail_meat_1.png",
    },
    {
      title: "다양한 시각적 자료를 통한 수출입 정보 제공",
      description:
        "국내에 수출입 쇠고기와 돼지고기에 대한 월별 데이터 제공\n전문가 리포트를 통한 전문적인 측면의 분석 데이터 제공",
      imageSource: "/static/asset/serviceDetail/img_detail_meat_2.png",
    },
    {
      title: "축산물 가격의 다양한 미래 예측 시나리오 제공",
      description:
        "목적에 맞게 학습된 알고리즘에 따라 단기/장기 예측 시나리오 제공",
      imageSource: "/static/asset/serviceDetail/img_detail_meat_3.png",
    },
  ],

  // lite
  [
    {
      title: "주요 KAPI 지수 및 데일리 리포트 제공",
      description:
        "농산물 대표 지수인 KAPI 추이를 통한 농산물 가격 변동성 확인\n매일 22가지 작물의 가격/거래량 데이터 제공",
      imageSource: "/static/asset/serviceDetail/img_detail_lite_1.png",
    },
    {
      title: "도매시장 별 농산물 상황과 단기 예측 시스템 제공",
      description:
        "작물별 도매시장의 가격/거래량 데이터 제공\n빅데이터와 인공지능을 활용한 고성능 단기 예측 분석 시스템을 통한 미래 예측 가능",
      imageSource: "/static/asset/serviceDetail/img_detail_lite_2.png",
    },
    {
      title: "인공지능 뉴스와 전문가 리포트로 고성능 전문적인 자료 제공",
      description: "인공지능이 분석한 농산물 가격/거래량 추이에 대한 뉴스 제공",
      imageSource: "/static/asset/serviceDetail/img_detail_lite_3.png",
    },
  ],
];

const services: Service[] = [
  {
    imageLogo: "/static/asset/terranlogo.svg",
    serviceName: "FARM",
    title: "TERRAN FARM 에서 데이터 분석을 통해 농업을 재해석 합니다.",
    imageSource: "/static/asset/img_service1.png",
    description: [
      "35개 공영 도매시장 경락 데이터를 바탕으로 가격 및 거래량 분석, 가격 예측, 농산물지수(KAPI) 등 다양한 인사이트를 제공.",
    ],
  },
  {
    imageLogo: "/static/asset/terranlogo.svg",
    serviceName: "MEAT",
    title: "TERRAN MEAT 에서 데이터 분석을 통해 축산을 재해석 합니다.",
    imageSource: "/static/asset/img_service2.png",
    description: [
      "축산물 경락 데이터를 가공 분석하여 지수 정보(KLPI), 가격 동향, 가격 예측 등을 제공.",
    ],
  },
  {
    imageLogo: "/static/asset/terranlogo.svg",
    serviceName: "LITE",
    title: "TERRAN LITE 에서 모바일로 농업인에게 다양한 정보를 제공합니다.",
    imageSource: "/static/asset/img_service3.png",
    description: [
      "Terran(Farm)을 바탕으로 핵심 기능만 적용한 경량 버전의 테란.  라이트 고유 기능, 전문가 리포트 제공.",
    ],
  },
];

export { cards, services, servicesDetails };
