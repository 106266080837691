import "./ServiceTemplateStyle.scss";
import Slider from "react-slick";
import React, { Component } from "react";
import { services, servicesDetails } from "../../info";

class ServiceTemplate extends Component {
  slider: any;
  detailSlider: any;

  state = {
    serviceIndex: 0,
    isModalOpen: false,
  };

  onMoveTerranLite = () => {
    window.open("https://www.terranlite.com", "_blank");
  };

  handleClickPrevious = () => {
    this.slider.slickPrev();
  };

  handleClickNext = () => {
    this.slider.slickNext();
  };

  handleSelectService = (index: number) => {
    this.setState({ isModalOpen: true, serviceIndex: index });
  };

  handleCloseModal = () => {
    this.setState({ ...this.state, isModalOpen: false });
  };

  render() {
    const settings = {
      className: "slider",
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      arrow: false,
    };

    const isMobile = window.innerWidth < 560;
    return (
      <div className="service_template">
        <article
          className={
            this.state.isModalOpen
              ? "service_modal"
              : "service_modal servce_modal_off"
          }
          onClick={this.handleCloseModal}
        >
          <section className="modal_content_box d-flex flex-column align-items-center">
            <div className="close_btn">CLOSE</div>

            {servicesDetails[this.state.serviceIndex].map((item, i) => (
              <section
                key={i}
                className="modal_content d-flex justify-content-center"
              >
                <div className="img_service_detail">
                  <img src={item.imageSource} alt="service_detail_img" />
                </div>

                <div className="service_detail">
                  <p className="service_detail_title">{item.title}</p>
                  {!isMobile && (
                    <p className="service_detail_content">{item.description}</p>
                  )}
                </div>
              </section>
            ))}
          </section>
        </article>

        <h1>OUR SERVICE</h1>
        <button
          className="service_slider_btn prev"
          onClick={this.handleClickPrevious}
        >
          <img src="/static/asset/arrow_left.svg" alt="previous button" />
        </button>
        <button
          className="service_slider_btn next"
          onClick={this.handleClickNext}
        >
          <img src="/static/asset/arrow_right.svg" alt="next button" />
        </button>
        <Slider
          ref={(el) => {
            this.slider = el;
          }}
          {...settings}
        >
          {services.map((item, i) => (
            <div className="col-12 service_box" key={item.title}>
              <div className="service_desc col-12">
                <div className="img_logo_box d-flex align-items-center">
                  <img src={item.imageLogo} alt="logo image" />

                  <p>- {item?.serviceName}</p>
                </div>

                <div className="img_logo_bar"></div>

                {i == 2 && (
                  <div className="service_link" onClick={this.onMoveTerranLite}>
                    이용하기
                  </div>
                )}

                <h4>
                  <p className="terran_title">{item.title}</p>
                  <p>{item.description}</p>
                </h4>
              </div>

              <div
                className="service_img d-flex justify-content-between align-items-end col-12"
                key={item.title}
              >
                <img src={item.imageSource} alt="terran service page" />

                <button
                  onClick={() => this.handleSelectService(i)}
                >{`DETAIL →`}</button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default ServiceTemplate;
