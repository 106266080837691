import React from "react";
import "./CardStyle.scss";

const Cards = (props: {
  title: string;
  src: string;
  description: string[];
  index: number;
}) => {
  const index = props.index;

  return (
    <div className={"about_card" + (index > 1 ? "_black" : "")}>
      <div>
        <p className={"title"}>{props.title}</p>
        <p className={"desc"}>
          {props.description.map((val, i) => (
            <p key={i}>{val}</p>
          ))}
        </p>
      </div>

      <div className="img_box">
        <img
          className={"img_about_card" + (index === 3 ? " big_img_card" : "")}
          src={props.src}
          alt={props.title}
        />
      </div>
    </div>
  );
};

export default Cards;
